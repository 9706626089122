import React from "react";
import { Link } from "react-router-dom";
import logoHeader from "../../assets/images/logo-white-02.png";

const NotFound = () => {
  return (
    <div className="not-found">
      <div className="container">
        <header>
          <img src={logoHeader} alt="Logo da Mobi2buy" />
        </header>
        <section>
          <p>404</p>
          <h1>Página não encontrada!</h1>
          <Link to="/" className="link-button">
            VOLTAR PÁGINA PRINCIPAL
          </Link>
        </section>
      </div>
    </div>
  );
};

export default NotFound;
