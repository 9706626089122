import React, { createContext, useContext } from "react";

export const AppContext = createContext();

function AppProvider({ children }) {
  const [sidebar, setSidebar] = React.useState(false);

  return (
    <AppContext.Provider value={{ sidebar, setSidebar }}>
      {children}
    </AppContext.Provider>
  );
}

export const useApp = () => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error(
      "You must surround your components with a AppProvider before call useApp"
    );
  }

  return context;
};

export default AppProvider;
