import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AppProvider from "../../context/AppProvider";
import Home from "../../pages/Home";
import NotFound from "../../pages/NotFound";
import Policies from "../../pages/Policies";

function MainRoutes() {
  return (
    <Router>
      <AppProvider>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/politicas" exact component={Policies} />
          <Route path="*" component={NotFound} />
        </Switch>
      </AppProvider>
    </Router>
  );
}

export default MainRoutes;
