import React from "react";
import renderHTML from "react-render-html";
import { date } from "../../mock/privacyPolicy";

const Topic = ({ topic, content }) => {
  return (
    <article>
      <h2 className="title">{content.title}</h2>
      {topic === "inicio" && <p className="date">{date}</p>}
      {content.content.map((content, index) => {
        return (
          <p className="content" key={index}>
            {renderHTML(content)}
          </p>
        );
      })}
      {content.subtopics && (
        <>
          {content.subtopics.map((subtopic) => {
            return (
              <article key={subtopic.id}>
                <h3 className="subtitle">{subtopic.title}</h3>
                {subtopic.content.map((content, index) => {
                  return (
                    <p className="content" key={index}>
                      {renderHTML(content)}
                    </p>
                  );
                })}
              </article>
            );
          })}
        </>
      )}
    </article>
  );
};

export default Topic;
