import React from "react";
import logo from "../../assets/images/logo.svg";

const MainFooter = () => {
  return (
    <footer id="main-footer">
      <div className="footer-header">
        <img src={logo} alt="Logo da Mobi2buy" />
        <p className="slogan">
          The Human-Driven <br /> Tech Company
        </p>
      </div>
      <p className="copyright">
        All rights reserved @2020 Mobi2buy <br /> Empowering people´s journeys.
      </p>
    </footer>
  );
};

export default MainFooter;
