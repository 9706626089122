import React from "react";
import Topic from "../../components/Topic";
import MainFooter from "../../components/MainFooter";
import MainHeader from "../../components/MainHeader";
import Sidebar from "../../components/Sidebar";
import NotFound from "../NotFound";
import { cookiePolicy, personalDataPolicy } from "../../mock/privacyPolicy";
import useQuery from "../../hooks/useQuery";

const Policies = () => {
  const [content, setContent] = React.useState(null);
  const [notFound, setNotFound] = React.useState(false);
  const type = useQuery("tipo");
  const topic = useQuery("topico");

  React.useEffect(() => {
    const typePolicy = type === "cookie" ? cookiePolicy : personalDataPolicy;
    let notFound = true;
    for (let item of typePolicy) {
      if (item.id === topic) {
        setContent(item);
        notFound = false;
        break;
      }
    }
    if (notFound) setNotFound(true);
  }, [type, topic]);

  if (notFound) return <NotFound />;
  if (content === null) return null;
  return (
    <div className="policies">
      <MainHeader />
      <section className="policy">
        <div className="container">
          <Topic topic={topic} content={content} />
        </div>
      </section>
      <MainFooter />
      <Sidebar />
    </div>
  );
};

export default Policies;
