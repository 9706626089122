import React from "react";
import renderHTML from "react-render-html";
import logo from "../../assets/images/logo.svg";
import iconMenu from "../../assets/images/icon-menu.svg";
import { useApp } from "../../context/AppProvider";
import useQuery from "../../hooks/useQuery";

const MainHeader = () => {
  const [title, setTitle] = React.useState("");
  const { setSidebar } = useApp();
  const type = useQuery("tipo");

  React.useEffect(() => {
    setTitle(type === "cookie" ? "cookies" : "<br />dados pessoais");
  }, [type]);

  return (
    <header id="main-header">
      <div className="container">
        <a href="/" className="logo">
          <img src={logo} alt="Logo da Mobi2buy" />
        </a>
        <h1>Política de {renderHTML(title)}</h1>
        <button
          className="burger"
          aria-label="Abrir menu lateral"
          onClick={() => setSidebar(true)}
        >
          <img src={iconMenu} alt="Abrir menu lateral" />
        </button>
      </div>
    </header>
  );
};

export default MainHeader;
