import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.svg";

const Home = () => {
  return (
    <div className="home">
      <header>
        <div className="container">
          <img src={logo} alt="Logo da Mobi2buy" />
        </div>
      </header>
      <section>
        <div className="container">
          <h1>Privacidade</h1>
          <div className="link-container">
            <Link
              to="/politicas?tipo=dados-pessoais&#38;topico=inicio"
              className="link-button"
            >
              Política de dados pessoais
            </Link>
            <Link
              to="/politicas?tipo=cookie&#38;topico=inicio"
              className="link-button"
            >
              Política de cookies
            </Link>
          </div>
        </div>
      </section>
      <footer>
        <div className="container">
          <div className="footer-header">
            <img src={logo} alt="Logo da Mobi2buy" />
            <p className="slogan">The Human-Driven Tech Company</p>
          </div>
          <p className="copyright">
            All rights reserved @2020 Mobi2buy <br /> Empowering people´s
            journeys.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Home;
