export const date = "Data: setembro/2020";

export const personalDataPolicy = [
  {
    id: "inicio",
    title: "Início",
    content: [
      "Nós da M2B Serviços Inteligentes de Telecom Ltda EPP nos preocupamos em proteger a sua privacidade de forma séria e responsável, tanto em nossos sites, como em listas e-mails marketing, mídias e redes sociais por nós controladas. Dessa forma, nossa Política de Privacidade, conforme diretrizes abaixo, rege nossas práticas de coleta, tratamento e uso de dados, bem como apresenta opções sobre uso, acesso e correção de informações.",
      "Esta Política é atualizada periodicamente, o que pode ser feito a qualquer momento, sem necessidade de notificação específica que não seja a publicação deste instrumento alterado no site, de maneira que é recomendável ao usuário sua verificação com frequência, consultando a data de sua última alteração na parte superior do texto. Ressalta-se que será necessária a adesão pelo usuário sempre que houver alteração de seus termos.",
      "Caso o usuário não concorde com as práticas descritas nesta Política de Privacidade e suas posteriores alterações, não deverá utilizar os sites, mídias e redes sociais, bem como o serviço de assinatura de e-mail marketing. Se continuar a utilizar o site ou a fornecer informações pessoais depois de uma alteração nos termos da Política de Privacidade, o usuário ficará vinculado pelo novo texto.",
      "A premissa principal da empresa em termos de dados pessoais é seu armazenamento e tratamento de forma segura e confidencial, em conformidade com as disposições específicas da <a target='_blank' href='https://presrepublica.jusbrasil.com.br/legislacao/612902269/lei-13709-18'>Lei Geral de Proteção de Dados Pessoais (Lei nº 13.709/18)</a>.",
      "Se o usuário tiver alguma dúvida sobre esta Política de Privacidade ou o tratamento que é dado aos dados pessoais fornecidos, e que não está esclarecido neste documento, deve ser remetido um e-mail para politicadeprivacidade@mobi2buy.com.",
      "Para os fins deste instrumento, são consideradas informações pessoais todas aquelas relacionadas à pessoa identificada ou identificável, notadamente, mas não exclusivamente, no que diz respeito ao seu nome completo, endereço, CPF, identidade, estado civil, telefones de contato, endereço de e-mail, dados de trabalho e situação laboral, dados de saúde e situação médica, dados bancários e de cartões de crédito, além de dívidas e contratações existentes e registradas, além de outras informações sobre o usuário, fornecidas diretamente pelo próprio ou obtidas através de informações públicas.",
      "Em complemento ao informado acima, também se enquadram como informações pessoais, os dados de navegação nos casos em que tais informações forem suficientes para identificar alguém de forma direta ou indireta, o que inclui informações sobre o computador do usuário e suas visitas aos sites, além de seu endereço IP, localização geográfica, tipo de navegador, fonte de referência, duração da visita e as páginas visualizadas.",
    ],
  },
  {
    id: "dados-que-coletamos",
    title: "Dados que coletamos",
    content: [
      "Quando o usuário visita os nossos sites e mídias e redes sociais, possui a liberdade para explorá-los sem fornecer nenhum dado pessoal. Contudo, para o acesso a áreas restritas e/ou quando há contratação ou cotação para serviços e/ou produtos, é solicitado o fornecimento de dados pessoais e é coletado, em complemento, dados de navegação. Se os dados pessoais não foram fornecidos em alguns setores, é possível que o usuário não consiga utilizar funcionalidades específicas do site, ou não obter cotações e orçamentos, por exemplo, mas sem maiores consequências.",
      "A empresa utiliza cookies ou tecnologias semelhantes para analisar tendências, administrar o site, rastrear os movimentos dos usuários no site e reunir informações demográficas sobre nossa base de usuários como um todo. Para saber como usamos cookies e como gerenciar suas preferências de cookies, consulte a nossa Política de Cookies.",
      "O uso de cookies e tecnologias semelhantes por qualquer empresa de rastreamento não está coberto pela nossa Política de Privacidade, tampouco pela nossa Política de Cookies.",
      "Quando o usuário utiliza os nossos serviços ou visualiza conteúdos que nós fornecemos, coletamos automaticamente informações sobre o hardware e o software do seu computador ou dispositivo móvel. Essas informações podem incluir endereço IP, tipo de navegador, nomes de domínio, provedor de serviços de Internet (ISP), arquivos visualizados no nosso site, sistema operacional, tempo de acesso e endereços de site de referência. Estas informações são usadas pela empresa para gerar estatísticas gerais a respeito do uso dos sites. Para esses fins, vinculamos esses dados coletados automaticamente aos dados pessoais do usuário.",
      "Quando baixa dados, utiliza aplicações móveis da empresa ou acessa a um dos nossos sites da Web otimizados para dispositivos móveis, podemos recolher informações sobre o usuário e sobre o seu dispositivo móvel. Especificamente dados de localização somente são coletados quando autorizados, expressamente, pelo usuário.  Estas informações são utilizadas para o fornecimento de serviços com base na localização, tais como resultados de pesquisa e outros conteúdos personalizados. Ressalta-se que é possível controlar e desativar serviços de localização a partir do menu de definições da maior parte dos dispositivos móveis.",
      "Os Sites não são destinados ou dirigidos a menores de 18 anos, e não são coletados dados de menores de 18 (dezoito) anos de forma consciente ou intencional, a não ser quando informados diretamente pelo usuário para fins de eventual contratação ou cotação de produtos e serviços.",
      "Todos os dados de pagamentos por cartão de crédito e/ou débito efetuados por link direto presente no site da empresa são processados por um serviço de processamento de pagamentos específico, M4U - M4 PRODUTOS E SERVIÇOS S.A., com sede na Rua Voluntários da Pátria, 113, 4º andar, Botafogo, Cidade do Rio de Janeiro, Estado do Rio de Janeiro, inscrita no CNPJ/MF sob o n° 09.614.276/0001-34, contratada como forma de facilitar a experiência de compra pelo usuário. Todas as informações recolhidas por estes prestadores terceiros para efeitos do processamento de seus pagamentos não estão disponíveis para a empresa, exceto se o usuário a tiver fornecido, de outro modo, em nossa plataforma própria.",
      "A empresa pode obter informações adicionais sobre o usuário a partir de cadastros mantidos por terceiros para complementação de dados de contratação/cotação, conforme autorizado por lei, como acesso a cadastros positivos ou restritivos ao crédito.",
    ],
  },
  {
    id: "finalidade-dos-dados-coletados",
    title: "Finalidade dos dados coletados",
    content: [
      "A empresa utiliza os dados que coletados somente em conformidade com esta Política de Privacidade. Eles não são vendidos ou repassados a terceiros, salvo nesta última hipótese com prévio consentimento do titular dos dados ou para fins de contratação e cotação de produtos e serviços, conforme solicitações feitas pelos próprios interessados/usuários. Assim, os dados pessoais dos usuários podem ser validamente partilhados com fornecedores ou parceiros comerciais que fornecem os bens ou serviços.",
      "Em complemento, os Dados Pessoais podem ainda ser utilizados pela empresa para: (i) melhorar sua experiência de navegação com a personalização dos Sites e para aprimorar nossas mídias e redes sociais; (ii) enviar aos usuários informações ou conteúdo da empresa e de parceiros; (iii) promover o uso dos serviços e produtos vinculados à empresa e nossos parceiros, bem como compartilhar com o usuário conteúdo promocional e informativo, obedecendo às suas preferências de comunicação; e (iv) contato direto com o usuário. Em resumo, a coleta e uso de Dados Pessoais pela empresa se justifica, dependendo do contexto em que se insere, pelo legítimo interesse da empresa, desde que não prejudique os interesses de proteção de dados ou direitos e liberdades fundamentais do usuário ou, ainda, quando necessário para a execução de contrato firmado com o cliente, contratação e cotação de produtos e serviços próprios ou de parceiros, ou em virtude de obrigação legal ou regulatória.",
      "<strong>Sempre que o fundamento para a coleta e uso de dados não se enquadrar nas hipóteses acima, será solicitado o consentimento expresso do usuário, deixando clara a possibilidade de não o fornecer, bem como as consequências desta negativa.</strong>",
      "No que diz respeito aos dados de navegação, eles são utilizados para operar e aprimorar os Sites e nossas mídias e redes sociais. Também utilizaremos os Dados de Navegação de maneira isolada ou em combinação com os Dados Pessoais para fornecer ao usuário informações personalizadas sobre a empresa e seus parceiros, seus produtos e serviços.",
    ],
  },
  {
    id: "seguranca-dos-dados-pessoais",
    title: "Segurança dos Dados Pessoais",
    content: [
      "A empresa utiliza várias tecnologias e procedimentos de segurança para ajudar a proteger os Dados Pessoais de seus usuários contra acesso, uso ou divulgação não autorizados. Todos os Dados Pessoais são protegidos pelas melhores práticas recomendadas pelos fabricantes das aplicações. Além disso, nossos parceiros e colaboradores estão submetidos aos termos de Pacto de Boas Práticas e Segurança no Tratamento de Dados, de maneira a garantir a confidencialidade dos dados pessoais informados e cumprimento dos termos da Lei nº 13.709/18.",
      "Esclarece-se, contudo, que os sites de nossa empresa fornecem links para outras páginas, que não são controlados por nós e, portanto, pelos quais não há responsabilidade por seu conteúdo ou práticas de tratamento, obtenção e segurança de dados pessoais. O fato de a empresa disponibilizar esses links não constitui endosso de seu conteúdo, dos proprietários ou das práticas por eles adotadas.",
      "Nesse sentido, destacamos que, além de páginas mantidas por parceiros de negócios, os sites da empresa possuem ligações ainda para redes sociais (como Facebook, Instagram, Twitter, Google+, Pinterest, Linkedin). Estas páginas e redes sociais são operadas exclusivamente por terceiros, não possuindo a empresa qualquer responsabilidade sobre os dados que são por si captados, tratados e armazenados. Assim, caso o usuário siga estes links, deverá verificar a política de privacidade e tratamento de dados específica de cada uma dessas plataformas.",
      "O servidor que habilita este website poderá localizar-se fora do país onde você acessou através do uso da Internet ou qualquer outra rede de comunicação eletrônica. Se for este o caso, o usuário desde já aceita e concorda que seus dados sejam processados inclusive através de diversas fronteiras nacionais e internacionais, desde que os países envolvidos possuam uma lei ou jurisprudência reiterada nos Tribunais locais de proteção aos dados pessoais.",
    ],
  },
  {
    id: "retencao-de-dados-pessoais",
    title: "Retenção de Dados Pessoais",
    content: [
      "Após o alcance da finalidade a que se destina a coleta de informações, a empresa arquivará tais dados pelo prazo de 05 (cinco) anos, salvo nas hipóteses em que autorizada ou que seja obrigatória a conservação por período maior. Passado este prazo, as informações coletadas poderão ser excluídas ou anonimizadas. Caso solicitado, o tratamento de dados poderá ser cessado mediante comunicação expressa do seu titular.",
      "Caso você tenha optado por receber nossos informes, utilizaremos informações sobre as suas preferências a partir da última data em que você expressou interesse pelo nosso conteúdo, produtos ou serviços, tal como a última vez em que você abriu um e-mail enviado pela empresa ou acessou nosso site de forma identificada. A empresa faz a retenção das informações derivadas de cookies e outras tecnologias de rastreamento a partir da data em que tais informações foram criadas.",
    ],
  },
  {
    id: "obrigacao-de-divulgacao",
    title: "Obrigação de divulgação",
    content: [
      "A empresa se reserva o direito de usar ou divulgar os Dados Pessoais do usuário se isso for exigido por lei/regulação ou se necessário para proteger direitos da empresa, proteger sua segurança ou a segurança de outros, investigar fraudes ou em virtude de ordem judicial.",
    ],
  },
  {
    id: "acesso-e-controle-de-dados-pessoais",
    title: "Acesso e controle de Dados Pessoais",
    content: [
      "O usuário pode solicitar acesso, correção, atualização ou exclusão de seus Dados Pessoais, bem como se opor ao seu tratamento ou pedir para restringi-lo – hipóteses em que será alertado sobre as suas consequências. O consentimento para tratamento de Dados Pessoais outorgado pelo seu titular poderá ser revogado a qualquer momento. Para exercer qualquer um desses direitos, o usuário deverá enviar um e-mail para politicadeprivacidade@mobi2buy.com.",
    ],
  },
  {
    id: "cancelamento-de-nossas-comunicacoes",
    title: "Cancelamento de nossas comunicações",
    content: [
      "O usuário poderá cancelar a assinatura de nossas comunicações de marketing clicando no link “cancelar assinatura” localizado na parte inferior de nossos e-mails, atualizando suas preferências de comunicação, ou ainda enviando um e-mail para politicadeprivacidade@mobi2buy.com.",
    ],
  },
];

export const cookiePolicy = [
  {
    id: "inicio",
    title: "Início",
    content: [
      "Quando você visita ou interage com o site da empresa, podemos usar cookies e outras tecnologias de rastreamento para armazenar informações, incluindo pixels (também conhecido como “web beacon” ou “clear GIFs”), armazenamento local, e identificadores de dispositivos, para ajudar a fornecer, proteger e melhorar nossos serviços. Para fins exemplificativos, utilizamos cookies e outros mecanismos assemelhados para salvar suas preferências, personalizar conteúdo, segmentar anúncios com base em seus interesses, combater fraudes e abusos, além de otimizar o funcionamento e o acesso ao site.",
      "Esta política explica como e por que usamos essas tecnologias e as opções que você tem. Usamos “cookies” e “tecnologias similares” de forma intercambiável para se referir a todas essas tecnologias.",
    ],
    subtopics: [
      {
        id: "o-que-sao-cookies",
        title: "O que são cookies?",
        content: [
          "Cookies são pequenos arquivos de texto colocados no seu computador, telefone ou outro dispositivo, quando você visita sites, e são usados para registrar informações sobre sua atividade, incluindo as páginas visualizadas, o conteúdo que você ouve, os anúncios clicados, suas configurações e outras ações que você executa.",
          "Os cookies podem ser cookies “persistentes” ou “de sessão”. Os cookies persistentes permanecem no seu dispositivo por um período definido ou até você excluí-los, enquanto os cookies da sessão são excluídos quando você fecha o navegador da web.",
        ],
      },
      {
        id: "tecnologias-semelhantes",
        title: "Tecnologias semelhantes",
        content: [
          "Usamos outras tecnologias de coleta de dados com funcionalidade semelhante aos cookies, incluindo pixels, armazenamento local e identificadores de dispositivo. Embora os nomes e tipos específicos de cookies que usamos possam mudar de tempos em tempos, à medida que aprimoramos e atualizamos o site, fornecemos algumas definições básicas para ajudar você a entender melhor nossa política, conforme abaixo.",
          "Pixels – Um pixel é uma pequena quantidade de código em uma página da web ou em uma notificação por email que normalmente funciona em conjunto com cookies para identificar nossos usuários e registrar o comportamento do usuário. Como muitos serviços, usamos pixels para saber se você interagiu com determinado conteúdo da Web ou de email. Isso nos ajuda a medir e melhorar nossos serviços e personalizar sua experiência.",
          "Armazenamento local – O armazenamento local é uma tecnologia padrão do setor que permite que um site ou aplicativo armazene informações localmente no seu computador ou dispositivo móvel. Usamos armazenamento local, incluindo HTML5, para personalizar o que mostramos com base no consumo de conteúdo e em outras interações anteriores com a Plataforma.",
          "Identificadores de dispositivo – Também usamos identificadores de dispositivo móvel e de publicidade para os mesmos fins articulados nesta política, por exemplo, para reconhecer seu dispositivo quando você retornar ao site.",
          "Kits de desenvolvimento de software – Podemos usar terceiros, incluindo nossos provedores de serviços e parceiros de negócios que usam kits móveis, para coletar informações para reconhecer seu dispositivo quando você retornar ao site. Podemos usar essa tecnologia, por exemplo, para analisar como você interage com o site, incluindo conteúdo e anúncios, e para permitir análises ou outros recursos através de dispositivos móveis.",
        ],
      },
      {
        id: "como-usamos-cookies",
        title: "Como usamos cookies",
        content: [
          "Usamos cookies para fornecer, medir e melhorar o site de várias maneiras. Os cookies que usamos geralmente se enquadram em uma das seguintes categorias:",
          "Operacional – Esses cookies são necessários para permitir a operação do site conforme solicitado. Por exemplo, eles permitem que autentiquemos sua conta e reconheçamos que tipo de assinante você é, para que possamos fornecer o site de acordo.",
          "Segurança / Abuso – Esses cookies ativam e suportam nossos recursos de segurança e nos ajudam a detectar atividades maliciosas ou não autorizadas. Também usamos cookies para combater fraudes e outras atividades que violam nossas políticas ou degradam nossa capacidade de fornecer o site. Os cookies também nos ajudam a combater ataques de spam e phishing, permitindo identificar computadores ou dispositivos usados para criar um grande número de contas falsas.",
          "Funcional / Preferências – Esses cookies permitem operar certas funções da Plataforma de acordo com as escolhas que você faz. Esses cookies permitem lembrar de você entre as visitas. Por exemplo, quando você continuar a usar ou voltar ao site, reconheceremos seu nome de usuário e lembraremos suas configurações e preferências de conteúdo. A exclusão desses tipos de cookies resultará em funcionalidade limitada do site.",
          "Desempenho / análise – usamos esses cookies para monitorar e avaliar o desempenho do site e onde pode ser aprimorado. Por exemplo, usamos esses cookies para testar diferentes versões do nosso site e saber quais recursos ou conteúdos os usuários preferem. Também usamos esses cookies para analisar como as pessoas acessam e usam nosso site. Por exemplo, podemos obter informações sobre o seu dispositivo e como os usuários se envolvem com o site ou em boletins por e-mail, incluindo se você abriu ou encaminhou um boletim ou clicou em qualquer conteúdo. Essas informações informam a eficácia de nossos boletins e ajudam a garantir que estamos fornecendo informações que você acha interessante.",
          "Personalização / publicidade – esses cookies usam informações sobre o uso do site, como as páginas que você visita, o conteúdo que ouve ou sua resposta a anúncios e e-mails, para que possamos recomendar conteúdo e exibir anúncios mais relevantes e interessantes para você, com base em critérios como seu perfil e atividade no site. Também podemos usar esses cookies para melhorar os relatórios sobre o desempenho da campanha, determinar quantas vezes as pessoas que clicam em seus anúncios acabam realizando uma ação (como a compra de produtos) e evitar a exibição repetida de anúncios que o usuário já viu.",
          "Marketing – Também trabalhamos com parceiros, como Google e Facebook, para rastrear a atividade do usuário e comercializar os nossos serviços e de empresas parceiras, mostrando anúncios pessoais dos produtos e dos serviços nos sites e aplicativos do site e de terceiros. Também usamos cookies para impedir que os assinantes vejam anúncios de qualquer produto ou serviço que eles já tenham comprado.",
        ],
      },
    ],
  },
  {
    id: "cookies-de-terceiros",
    title: "Cookies de terceiros",
    content: [
      "Permitimos que certos prestadores de serviços coloquem cookies e tecnologias semelhantes no site para os mesmos fins listados acima, incluindo a coleta de informações sobre suas atividades on-line ao longo do tempo e em diferentes sites e dispositivos, a fim de fornecer publicidade mais relevante.",
      "Por exemplo, para nos ajudar a entender melhor como as pessoas se envolvem com o site, trabalhamos com parceiros de análise, incluindo o Google Analytics. Para desativar o Google Analytics, você pode instalar o Navegador de desativação do Google Analytics aqui. Para desativar o Google Analytics para exibir anúncios ou personalizar anúncios da Rede de Display do Google, você pode visitar a página Configurações de anúncios do Google.",
      "Você também pode encontrar cookies e tecnologias de rastreamento de nossos provedores de serviços de marketing de terceiros, incluindo (1) o Facebook para fornecer serviços de publicidade por meio das plataformas de publicidade e marketing do Facebook (por exemplo, Audience Network, Custom Audiences e Facebook Pixel) e outras redes sociais, e (2) o Google para fornecer serviços de publicidade por meio das plataformas de publicidade e marketing do Google (por exemplo, DoubleClick for Publishers, Google AdX e Adwords).",
      "Além disso, podemos trabalhar com redes de publicidade e trocas programáticas para nos permitir exibir anúncios para você. Essas redes de anúncios de terceiros e provedores de troca podem usar cookies, pixels ou tecnologias similares de terceiros para coletar informações, incluindo o identificador do dispositivo, endereço IP ou identificador para publicidade. As informações coletadas por terceiros podem ser usadas para nos ajudar a fornecer a você publicidade mais relevante que veiculamos na Plataforma ou fora dela, e conforme descrito acima.",
      "Os cookies de terceiros são cobertos pela política de privacidade de terceiros.",
    ],
    subtopics: [
      {
        id: "suas-escolhas-e-controles",
        title: "Suas escolhas e controles",
        content: [
          "Estamos comprometidos em oferecer opções de privacidade significativas. Você tem várias opções para controlar ou limitar a forma como nós, nossos parceiros e outros terceiros, usamos cookies. No entanto, observe que os cookies são importantes para muitos aspectos do site – se você desativar os cookies, poderá não ser capaz de aproveitar todos os recursos do site. Na medida em que você opte pela publicidade personalizada usando os mecanismos abaixo, você ainda poderá receber anúncios ao usar o site, mas eles não serão adaptados aos seus interesses.",
        ],
      },
    ],
  },
  {
    id: "configuracoes-do-navegador",
    title: "Configurações do navegador",
    content: [
      "Para controlar os cookies, você pode modificar suas configurações na maioria dos navegadores da Web para aceitar ou negar cookies ou solicitar sua permissão sempre que um site tentar definir um cookie. Você também pode excluir manualmente os cookies armazenados anteriormente a qualquer momento. Observe que, se você optar por bloquear os cookies, isso poderá prejudicar o site ou impedir que certos elementos dela funcionem.",
    ],
  },
  {
    id: "configuracoes-do-dispositivo",
    title: "Configurações do dispositivo",
    content: [
      "No seu dispositivo móvel, ative a configuração “Limitar o rastreamento de anúncios” nas configurações do seu telefone iOS ou a opção “Desativar a personalização de anúncios” nas configurações do seu telefone Android. Para desativar o rastreamento de local ao usar nossos aplicativos, você pode usar as configurações do seu dispositivo. Você pode desativar a análise móvel e os relatórios de erros através dessas mesmas configurações.",
    ],
  },
  {
    id: "fale-conosco",
    title: "Fale conosco",
    content: [
      "Se você tiver alguma dúvida sobre o uso de cookies, entre em contato conosco pelo politicadeprivacidade@mobi2buy.com.",
    ],
  },
];

const policies = { date, personalDataPolicy, cookiePolicy };

export default policies;
