import React from "react";
import iconClose from "../../assets/images/icon-close.svg";
import { Link } from "react-router-dom";
import { useApp } from "../../context/AppProvider";
import { personalDataPolicy, cookiePolicy } from "../../mock/privacyPolicy";
import useQuery from "../../hooks/useQuery";

const Sidebar = () => {
  const type = useQuery("tipo");
  const topic = useQuery("topico");
  const { sidebar, setSidebar } = useApp();

  function handleOutClick(e) {
    if (e.target === e.currentTarget) {
      setSidebar(false);
    }
  }

  function handleClick() {
    setSidebar(false);
  }

  return (
    <div
      className={`sidebar${sidebar ? " active" : ""}`}
      onClick={handleOutClick}
    >
      <nav className="content">
        <button
          className="close"
          aria-label="Fechar menu lateral"
          onClick={handleClick}
        >
          <img src={iconClose} alt="Fechar menu lateral" />
        </button>
        <div>
          <h3>POLÍTICA DE DADOS PESSOAIS</h3>
          <ul>
            {personalDataPolicy.map(({ id, title }) => {
              return (
                <li key={id}>
                  <Link
                    className={
                      id === topic && type === "dados-pessoais" ? "active" : ""
                    }
                    onClick={handleClick}
                    to={`/politicas?tipo=dados-pessoais&topico=${id}`}
                  >
                    {title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div>
          <h3>POLÍTICA DE COOKIES</h3>
          <ul>
            {cookiePolicy.map(({ id, title }) => {
              return (
                <li key={id}>
                  <Link
                    className={
                      id === topic && type === "cookie" ? "active" : ""
                    }
                    onClick={handleClick}
                    to={`/politicas?tipo=cookie&topico=${id}`}
                  >
                    {title}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
